import { ApiDownloadReport, ApiDownloadReportBak } from '/src/api/export';
// import { base64ToUrl, base64ToByteArray } from '/src/utils/index';

import * as fzstd from 'fzstd';

export default {
    data() {
        return {
            downloading: false,
        };
    },
    computed: {
        userInfo() {
            return this.$store.getters.userInfo || {};
        },
        staticData() {
            return this.$store.getters.staticData;
        },
        vipFlag() {
            return this.$store.getters.vipFlag || false;
        },
        // 剩余预览和下载次数
        stillCount() {
            return this.$store.getters.stillCount;
        },
    },
    methods: {
        // 下载报告
        handleReportDownload({
            orderId = '',
            isDownload = false,
            pcFlag = true,
            reportId = '',
            name = '',
        }) {
            return new Promise(async (resolve, reject) => {
                if (this.downloading || !reportId) {
                    return false;
                }

                const tmpStillCount = await this.$store.dispatch(
                    'GetStillCount'
                );

                // 下载次数用完
                if (!tmpStillCount.stilldownloadCount) {
                    reject(reportId);

                    return false;
                }
                // 您今日可下载次数共XX次，剩余可用次数XX次
                let tmpMsg = '<p>下载文件将消耗今日 1 次次数，确认下载吗？</p>';

                if (isDownload) {
                    // 该文件已下载，不消耗下载次数
                    tmpMsg = '<p>该文件已下载，不消耗下载次数</p>';
                }

                tmpMsg +=
                    '<p style="color: #999;">您今日可用下载次数共 ' +
                    tmpStillCount.downloadCount +
                    ' 次，剩余可用次数 ' +
                    tmpStillCount.stilldownloadCount +
                    ' 次</p>';

                this.$confirm(tmpMsg, '提示', {
                    dangerouslyUseHTMLString: true,
                    confirmButtonText: '确认下载',
                    cancelButtonText: '稍后再说',
                })
                    .then(action => {
                        if (action === 'confirm') {
                            this.downloading = true;

                            const fileType = '.pdf';
                            const tmpFileNmae = name + fileType;

                            // 使用zstd的base64解析
                            ApiDownloadReport({ orderId, pcFlag, reportId })
                                .then(async res => {
                                    this.downloading = false;

                                    const tmpUrl =
                                        await this.handleGetUrlByZstd(
                                            res.data
                                        ).catch(() => {
                                            this.$message.error(
                                                '文件数据解析发生错误！'
                                            );
                                        });

                                    this.handleCreateDownloadLink(
                                        tmpFileNmae,
                                        tmpUrl
                                    );

                                    resolve();
                                })
                                .catch(() => {
                                    this.downloading = false;

                                    reject();
                                });

                            // // 使用pdf的base64解析
                            // ApiDownloadReportBak({ orderId, pcFlag, reportId })
                            //     .then(async res => {
                            //         this.downloading = false;

                            //         const tmpUrl = await base64ToUrl({
                            //             codes: res.data,
                            //             type: fileType,
                            //         }).catch(() => {
                            //             this.$message.error(
                            //                 '文件数据解析发生错误！'
                            //             );
                            //         });

                            //         this.handleCreateDownloadLink(
                            //             tmpFileNmae,
                            //             tmpUrl
                            //         );

                            //         resolve();
                            //     })
                            //     .catch(() => {
                            //         this.downloading = false;

                            //         reject();
                            //     });
                        } else {
                            reject();
                        }
                    })
                    .catch(() => {
                        reject();
                    });
            });
        },

        // 生成zstd格式的数据
        handleGetUrlByZstd(zstBase64) {
            return new Promise((resolve, reject) => {
                try {
                    // 将base64字符串转换为二进制数据
                    const binaryData = window.atob(zstBase64);

                    // 创建一个Uint8Array缓冲区
                    const tmpUint8Array = new Uint8Array(binaryData.length);

                    // 将二进制数据复制到Uint8Array缓冲区中
                    for (let i = 0; i < binaryData.length; i++) {
                        tmpUint8Array[i] = binaryData.charCodeAt(i);
                    }

                    const decompressedData = fzstd.decompress(tmpUint8Array);
                    const tmpBlob = new Blob([decompressedData], {
                        // type: 'application/octet-stream',
                        type: 'application/pdf',
                    });

                    resolve(URL.createObjectURL(tmpBlob));
                } catch (error) {
                    // 如果出现错误，则将 Promise 标记为失败状态，并返回空字符串
                    console.error(error);

                    reject(error);
                }
            });
        },

        // 生成下载链接并下载
        handleCreateDownloadLink(fileName, tmpUrl) {
            if (!tmpUrl) {
                return false;
            }
            const tmpLink = document.createElement('a');

            tmpLink.setAttribute('download', fileName);
            tmpLink.style.display = 'none';
            tmpLink.href = tmpUrl;

            document.body.appendChild(tmpLink);

            tmpLink.click();
            tmpLink.remove();
        },

        // 获取剩余查看和下载次数
        handleGetStillCount(type) {
            if (type === 'again') {
                this.handleToastShow(type);

                return false;
            }

            this.$store
                .dispatch('GetStillCount')
                .then(() => {
                    this.handleToastShow(type);
                })
                .catch(() => {});
        },

        // 展示轻提示
        handleToastShow(type = '') {
            if (type) {
                this.detailReportShow = true;
                this.detailReportType = type;

                // 剩余查看和下载次数
                window.setTimeout(() => {
                    this.detailReportShow = false;
                }, 3500);
            }
        },
    },
};
