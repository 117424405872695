<template>
    <div class="banner">
        <el-image
            src="/images/banner.jpg"
            fit="cover"
            class="banner-image"
            alt="banner"
        >
        </el-image>

        <div class="wrap">
            <div class="keywords" :class="keywordClass">
                <div class="keywords-title">
                    <img src="/images/slogan.svg" alt="slogan" />
                </div>

                <div class="keywords-input">
                    <el-input
                        v-model="keyWordValue"
                        placeholder="输入您要搜索的关键词"
                        @focus="handleKeywordsFocus"
                        @blur="handleKeywordsBlur"
                        @change="handleKeywordsChange"
                        @keyup.enter.native="handleKeywordsEnter"
                    >
                        <el-button
                            slot="suffix"
                            icon="el-icon-search"
                            circle
                            class="keyword-icon-search"
                            @click="handleKeywordsEnter"
                        ></el-button>
                    </el-input>
                </div>

                <div v-if="hotWords.length" class="keywords-hot">
                    <span>热门搜索：</span>
                    <a
                        v-for="(item, index) in hotWords"
                        :key="'hot-key-' + index"
                        v-track:click
                        href="javascript:void(0);"
                        :track-params="'t:click_homepage_hotkeyword;p:' + item"
                        @click="handleHotWordClick(item)"
                        >{{ item }}</a
                    >
                </div>

                <div class="data-statistics">
                    <div class="data-item">
                        <div class="data-item-value">
                            {{ dataStatistics.totalAmount || '-' }}
                        </div>
                        <div class="data-item-label">报告总数</div>
                    </div>

                    <div class="data-item">
                        <div class="data-item-value">
                            {{ dataStatistics.todayAmount || '-' }}
                        </div>
                        <div class="data-item-label">今日上新</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ApiGetHotWordsWithAmount } from '/src/api/search';

export default {
    name: 'HomeBanner',
    data() {
        return {
            loading: false,

            keywordClass: '',
            keyWordValue: this.$route.query?.keyword || '',

            hotWords: [
                // '新能源',
                // '新零售',
                // '智慧农业',
                // '互联网下半场',
                // '元宇宙',
            ],

            // 统计数据
            dataStatistics: {
                totalAmount: '',
                todayAmount: '',
            },
        };
    },
    computed: {
        userInfo() {
            return this.$store.getters.userInfo || {};
        },
        vipFlag() {
            return this.$store.getters.vipFlag || false;
        },
    },
    created() {},
    mounted() {
        // 默认发起一次请求
        this.handleGetHotWords();
    },
    methods: {
        // 获取列表
        handleGetHotWords() {
            if (this.loading) {
                return false;
            }

            this.loading = true;

            ApiGetHotWordsWithAmount()
                .then(res => {
                    this.loading = false;

                    const tmpData = res.data || {};

                    this.hotWords = tmpData.hotWords || [];
                    this.dataStatistics = {
                        totalAmount: tmpData.totalAmount,
                        todayAmount: tmpData.todayAmount,
                    };
                })
                .catch(() => {
                    this.loading = false;
                });
        },

        // 登录及VIP判断
        handleIsLoginAndVip(noVip) {
            const { userInfo, vipFlag } = this;

            if (!userInfo.weiXinUnionId) {
                // 未登录
                return false;
            } else if (!noVip && !vipFlag) {
                // 开通VIP
                return false;
            }

            return true;
        },

        // 触发父组件
        handleToEmit(type, data) {
            this.$emit('change', {
                type,
                data,
            });
        },

        handleKeywordsFocus() {
            // if (!this.handleIsLoginAndVip(true)) {
            //     this.handleToEmit('loginAndVip');
            //     return false;
            // }
        },

        handleKeywordsBlur() {
            setTimeout(() => {
                // 延迟处理
                this.keywordClass = '';
            }, 300);
        },

        handleKeywordsChange() {
            this.handleToSearch(this.keyWordValue);
        },

        handleKeywordsEnter() {
            this.handleToSearch(this.keyWordValue);
        },

        handleHotWordClick(value) {
            this.handleToSearch(value);
        },

        handleToSearch(value) {
            if (!this.handleIsLoginAndVip(true)) {
                this.handleToEmit('loginAndVip');

                return false;
            }

            this.$router.push('/?keyword=' + value);
        },
    },
};
</script>

<style lang="scss" scoped>
.banner {
    position: relative;
    height: 300px;

    &-image {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 0;
        display: block;
        width: 100%;
        height: 300px;
        background-color: #999;
    }

    .wrap {
        height: 100%;
    }

    .keywords {
        position: relative;

        &-title {
            height: 80px;
            padding-top: 40px;
            margin-bottom: 20px;
            text-align: center;

            img {
                display: block;
                width: 513px;
                height: auto;
                margin: 0 auto;
            }
        }

        &-input {
            width: 600px;
            height: 60px;
            background: #fff;
            border-radius: 30px;
            margin: 0 auto 16px;
            overflow: hidden;

            :deep(.el-input) {
                font-size: 16px;
                width: 100%;
                height: 100%;
            }

            :deep(.el-input__inner) {
                border-radius: 0;
                border: none;
                height: 60px;
                line-height: 60px;
                padding: 0 65px 0 28px;
            }
        }

        .keyword-icon-search {
            width: 50px;
            height: 50px;
            background: linear-gradient(
                60deg,
                #0cb9e4,
                #058fe7,
                #5540ff,
                #9527dd
            );
            border: none;
            border-radius: 30px;
            margin-top: 5px;
            font-size: 24px;
            color: #fff;
        }

        &-hot {
            text-align: center;

            span,
            a {
                margin: 0 8px;
                font-size: 14px;
                font-weight: 400;
                color: rgba(255, 255, 255, 60%);
            }

            a:hover {
                text-decoration: underline;
            }
        }
    }

    .data-statistics {
        position: absolute;
        top: 144px;
        right: 920px;
        display: flex;
        font-size: 14px;
        color: #fff;
        line-height: 1.4;
        text-align: center;

        .data-item {
            position: relative;
            padding: 5px 10px;

            + .data-item {
                &::before {
                    position: absolute;
                    left: 0;
                    top: 15%;
                    bottom: 15%;
                    content: ' ';
                    width: 1px;
                    background-color: #fff;
                }
            }

            &-value {
                font-size: 16px;
            }
        }
    }
}
</style>
