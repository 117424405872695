<template>
    <div v-show="detailReportShow" class="detail-report">
        <div v-if="detailReportType === 'preview'">
            今日已查看报告<em>{{ stillCount.previewCount }}</em
            >次，仍可查看<em>{{ stillCount.stillPreviewCount }}</em
            >次
        </div>
        <div v-else-if="detailReportType === 'download'">
            今日已下载报告<em>{{ stillCount.downloadCount }}</em
            >次，仍可下载<em>{{ stillCount.stilldownloadCount }}</em
            >次
        </div>
        <div v-else-if="detailReportType === 'again'">
            您今日已下载过该文件，本次下载不扣除可用次数
        </div>
    </div>
</template>

<script>
export default {
    name: 'DetailReport',
    props: {
        detailReportShow: {
            type: Boolean,
            default: false,
        },
        detailReportType: {
            type: String,
            default: '',
        },
    },
    data() {
        return {};
    },
    computed: {
        // 剩余预览和下载次数
        stillCount() {
            return this.$store.getters.stillCount;
        },
    },
    created() {},
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
.detail-report {
    position: fixed;
    top: 50%;
    left: 50%;
    z-index: 20000;
    padding: 20px 38px;
    text-align: center;
    background: rgba(0, 0, 0, 70%);
    border-radius: 10px;
    transform: translate(-50%, -50%);
    font-size: 18px;
    font-weight: 400;
    color: #fff;
    line-height: 1.2;

    em {
        padding: 0 6px;
    }
}
</style>
